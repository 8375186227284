import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Footer extends Component {
  
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__col'>
              <ul>
                <li><Link to='/buy/'>Buy</Link></li>
                <li><Link to='/about/'>About</Link></li>
                <li><Link to='/lease/'>Lease</Link></li>
                <li><button onClick={() => document && document.querySelector('.header__nav--left li:first-of-type .header__subnav li button').click()}>Appraisals</button></li>
                <li><Link to='/leased/'>Leased</Link></li>
                <li><button onClick={() => document && document.querySelector('.header__nav--right li button').click()}>Contact</button></li>
                <li><Link to='/sold/'>Sold</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <div className='footer__logo'>
                <Link to='/'>
                  <Logo color='#000000' />
                </Link>
              </div>
              <ul>
                <li><Link to='https://www.google.com/maps/place/284+La+Trobe+St,+Melbourne+VIC+3000/data=!4m2!3m1!1s0x6ad65d353248deb5:0x34a6df35e4923926?sa=X&ved=2ahUKEwiIwM3y-_r7AhWQ9DgGHZVgC1kQ8gF6BAgeEAE'>284 La Trobe Street<br />Melbourne VIC 3000</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <ul>
                <li><Link to='https://www.instagram.com/corerealty_au/'>Instagram</Link></li>
                <li><Link to='https://www.facebook.com/CoreRealty/'>Facebook</Link></li>
                <li><Link to='tel:+61393293266'>Call Us</Link></li>
              </ul>
            </div>
          </div>
          <div className='footer__bottom'>
            <div className='footer__col'>
              <ul>
                <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <ul>
                <li>© Core Realty, { new Date().getFullYear() }</li>
                <li><Link to='https://www.lbdstudios.com.au/' title={'Site credit: LBD STUDIOS'}>LBD STUDIOS</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
