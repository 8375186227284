import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'
import AppraisalForm from './appraisal-form'
import ContactForm from './contact-form'

class Header extends Component {

  state = {
    scrolled: false,
    offCanvas: false,
    contact: false,
    appraisal: false,
    appraisal_type: null,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.scrollDetect)
	}

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > window.innerHeight,
    })
  }

	scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
	}

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled, appraisal, contact } = this.state
    let { intro } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={`header${scrolled ? ' header--scrolled' : ''}${offCanvas ? ' header--active' : ''}${intro ? ' header--intro-active' : ''}`}>
          <div className='header__inner'>
            <nav className='header__nav header__nav--left'>
              <ul>
                <li>
                  <Link to='/buy/' {...props}>Sales</Link>
                  <ul className='header__subnav'>
                    <li><Link to='/buy/' {...props}>Buy</Link></li>
                    <li><Link to='/sold/' {...props}>Sold</Link></li>
                    <li>
                      <button 
                        type='button'
                        onClick={() => this.setState({ appraisal: true, appraisal_type: 'Sales Appraisal' })}
                      >
                          Appraisals
                      </button>
                      
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/lease/' {...props}>Rentals</Link>
                  <ul className='header__subnav'>
                    <li><Link to='/lease/' {...props}>Lease</Link></li>
                    <li><Link to='/leased/' {...props}>Leased</Link></li>
                    <li><Link to='/commercial-lease/' {...props}>Commercial</Link></li>
                    <li>
                      <button 
                        type='button'
                        onClick={() => this.setState({ appraisal: true, appraisal_type: 'Rental Appraisal' })}
                      >
                          Appraisals
                      </button>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to='/projects/' {...props}>Projects</Link>
                </li>
              </ul>
            </nav>
            <Link to='/' title='Core Realty' className='header__logo' {...props}>
              <Logo color='#000000' />
            </Link>
            <nav className='header__nav header__nav--right'>
              <ul>
                <li>
                  <Link to='/about/' {...props}>About</Link>
                  <ul className='header__subnav'>
                    <li><Link to='/about/' {...props}>About</Link></li>
                    <li><Link to='/team/' {...props}>Team</Link></li>
                  </ul>
                </li>
                <li>
                  <button 
                    type='button'
                    onClick={() => this.setState({ contact: true })}
                  >
                      Contact
                  </button>
                </li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className='header__hamburger'>
              { !offCanvas && <span className='lines'></span> }
              { offCanvas && <span className='close'>Close</span> }
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/lease/' {...props}>Lease</Link></li>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
                <li><Link to='/leased/' {...props}>Leased</Link></li>
                <li><Link to='/projects/' {...props}>Projects</Link></li>
                <li>
                  <button 
                    type='button'
                    onClick={() => this.setState({ appraisal: true })}
                  >
                      Appraisals
                  </button>
                </li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li>
                  <button 
                    type='button'
                    onClick={() => this.setState({ contact: true })}
                  >
                      Contact
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={`appraisal ${appraisal ? ' active' : ''}`}>
          <div className='appraisal__overlay'>
            <div className='appraisal__form'>
              <button type='button' onClick={() => this.setState({ appraisal: false, appraisal_type: null })} className='appraisal__close'>Close</button>
              <div className='appraisal__form-wrapper'>
                <AppraisalForm id={'header'} location={this.props.location} appraisal_type={this.state.appraisal_type} />
              </div>
            </div>
          </div>
        </div>
        <div className={`appraisal ${contact ? ' active' : ''}`}>
          <div className='appraisal__overlay'>
            <div className='appraisal__form'>
              <button type='button' onClick={() => this.setState({ contact: false })} className='appraisal__close'>Close</button>
              <div className='appraisal__form-wrapper'>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
