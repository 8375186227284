
import React from 'react'

export const Logo = ({ color = '#141414' }) => (
  <svg width="1321" height="604" viewBox="0 0 1321 604" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M489.06 603.97V505.57H566.43V516.89H501.28V545.77H554.98V557.09H501.28V592.65H566.43V603.97H489.06V603.97Z" fill={color}/>
    <path d="M362 603.75V505.06H418.59C428.24 505.06 435.81 507.49 441.29 512.35C446.77 517.21 449.51 523.93 449.51 532.51C449.51 539.66 447.37 545.78 443.08 550.88C438.79 555.97 433.13 559.15 426.1 560.4L450.77 603.75H437.18L412.51 560.57H374.25V603.75H362ZM374.25 549.04H417.79C423.99 549.04 428.68 547.67 431.87 544.93C435.06 542.19 436.65 538.17 436.65 532.86C436.65 527.55 435.06 523.64 431.87 520.75C428.68 517.86 424.2 516.42 418.42 516.42H374.26V549.05L374.25 549.04Z" fill={color}/>
    <path d="M591.91 603.75L640.27 505.06H650.46L699.36 603.75H686.04L672.63 577.2H618.1L605.05 603.75H591.91V603.75ZM623.73 565.67H667.17L645.36 521.95L623.73 565.67V565.67Z" fill={color}/>
    <path d="M726.7 603.75V505.06H738.95V592.4H803.4V603.75H726.7Z" fill={color}/>
    <path d="M848.05 603.75V516.41H806.57V505.06H901.95V516.41H860.39V603.75H848.05V603.75Z" fill={color}/>
    <path d="M962.22 603.75V554.32L917.79 505.06H933.16L968.47 544.04L1003.6 505.06H1019.16L974.47 554.5V603.75H962.22V603.75Z" fill={color}/>
    <path d="M57.86 147C57.86 166.52 60.96 184.03 67.17 199.54C73.38 215.06 81.92 228.18 92.77 238.92C103.64 249.66 116.46 257.87 131.23 263.56C146.01 269.25 162.04 272.09 179.33 272.09C189.68 272.09 199.65 270.19 209.25 266.4C218.86 262.61 227.77 257.66 235.97 251.56C244.17 245.45 251.52 238.57 258.02 230.92C264.53 223.27 269.84 215.51 273.98 207.65L294.82 220.29C288.76 229.7 281.6 238.72 273.32 247.35C265.04 255.98 255.48 263.64 244.61 270.31C233.74 276.98 221.48 282.28 207.81 286.21C194.14 290.14 178.88 292.11 162.03 292.11C137.64 292.11 115.51 288.25 95.64 280.53C75.76 272.81 58.73 262.35 44.54 249.15C30.35 235.96 19.38 220.54 11.62 202.92C3.88 185.3 0 166.66 0 147C0 133.94 1.78 121.2 5.32 108.77C8.87 96.35 14 84.69 20.73 73.81C27.45 62.93 35.65 52.96 45.34 43.9C55.02 34.85 65.96 27.05 78.15 20.52C90.34 13.99 103.68 8.94001 118.16 5.36001C132.64 1.78001 148.08 -0.00999451 164.5 -0.00999451C180.92 -0.00999451 194.61 1.99001 207.84 5.99001C221.06 9.99001 233.11 15.29 243.97 21.89C254.84 28.49 264.51 36.11 273.01 44.74C281.5 53.37 288.79 62.4 294.84 71.8L273.34 85.28C261.08 62.68 247.33 46.14 232.11 35.68C216.88 25.22 199.37 19.99 179.57 19.99C162.58 19.99 146.68 22.94 131.91 28.84C117.12 34.74 104.23 43.16 93.23 54.11C82.22 65.06 73.57 78.37 67.29 94.02C61 109.68 57.87 127.33 57.87 146.99L57.86 147Z" fill={color}/>
    <path d="M535.24 292.1C512.48 292.1 491.24 288.24 471.5 280.52C451.77 272.8 434.63 262.23 420.07 248.83C405.51 235.42 394.06 219.77 385.71 201.86C377.36 183.96 373.19 164.83 373.19 144.47C373.19 131.13 375.08 118.29 378.85 105.93C382.61 93.58 387.97 82.06 394.92 71.39C401.87 60.72 410.26 50.99 420.08 42.22C429.91 33.45 440.81 25.94 452.78 19.69C464.75 13.44 477.72 8.6 491.68 5.16C505.65 1.72 520.17 0 535.24 0C550.31 0 565.02 1.72 578.91 5.16C592.8 8.61 605.76 13.45 617.81 19.69C629.85 25.94 640.75 33.45 650.51 42.22C660.26 51 668.65 60.72 675.67 71.39C682.69 82.06 688.08 93.57 691.85 105.93C695.62 118.29 697.51 131.13 697.51 144.47C697.51 157.81 695.62 171.15 691.85 183.64C688.09 196.14 682.69 207.86 675.67 218.81C668.65 229.76 660.26 239.73 650.51 248.72C640.75 257.71 629.86 265.43 617.81 271.88C605.76 278.34 592.8 283.33 578.91 286.83C565.01 290.34 550.45 292.09 535.24 292.09V292.1ZM535.24 278.2C552.08 278.2 566.97 274.86 579.91 268.19C592.84 261.52 603.74 252.22 612.61 240.28C621.47 228.35 628.16 214.24 632.67 197.95C637.18 181.66 639.43 163.83 639.43 144.46C639.43 125.09 637.18 107.61 632.67 91.6C628.16 75.59 621.47 61.8 612.61 50.22C603.74 38.64 592.84 29.65 579.91 23.26C566.97 16.87 552.08 13.68 535.24 13.68C518.4 13.68 503.06 16.87 490.13 23.26C477.19 29.65 466.33 38.63 457.54 50.22C448.74 61.8 442.13 75.6 437.69 91.6C433.26 107.6 431.04 125.23 431.04 144.46C431.04 163.69 433.26 181.67 437.69 197.95C442.13 214.24 448.74 228.35 457.54 240.28C466.33 252.22 477.19 261.52 490.13 268.19C503.06 274.86 518.09 278.2 535.24 278.2Z" fill={color}/>
    <path d="M786.72 6.32001H888.7C906.28 6.32001 921.73 8.11001 935.03 11.69C948.33 15.27 959.42 20.29 968.29 26.75C977.16 33.21 983.84 40.9 988.35 49.81C992.86 58.73 995.11 68.45 995.11 78.98C995.11 87.68 993.37 95.83 989.9 103.41C986.43 110.99 981.55 117.83 975.27 123.94C968.98 130.05 961.41 135.31 952.54 139.74C943.68 144.16 933.92 147.64 923.28 150.17L1030.35 285.59H972.93L870.73 154.38H836.15V285.59H786.72V6.32001V6.32001ZM836.15 140.26H864.75C875.84 140.26 886.07 138.86 895.45 136.05C904.84 133.24 912.97 129.28 919.84 124.15C926.71 119.02 932.07 112.81 935.91 105.51C939.75 98.21 941.68 90 941.68 80.87C941.68 72.45 940.16 64.73 937.13 57.71C934.11 50.69 929.82 44.69 924.28 39.7C918.73 34.72 912.16 30.82 904.54 28.01C896.93 25.21 888.62 23.8 879.6 23.8H836.15V140.26V140.26Z" fill={color}/>
    <path d="M1320.27 6.32001V24.43H1165.31V124.26H1269.06V142.37H1165.31V267.47H1320.27V285.58H1115.88V6.32001H1320.27Z" fill={color}/>
  </svg>
)

export const Instagram = ({ color = '#000000' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92285C14.0378 3.92285 14.3784 4.26342 14.3784 4.68319C14.3784 5.10327 14.0378 5.44385 13.6178 5.44385C13.198 5.44385 12.8574 5.10327 12.8574 4.68319C12.8574 4.26342 13.198 3.92285 13.6178 3.92285ZM6.16642 2.10742H12.0954C14.3216 2.10742 16.1424 3.91917 16.1424 6.13309V12.0917C16.1424 14.3056 14.3217 16.1171 12.0954 16.1171H6.16642C3.94016 16.1171 2.11914 14.3056 2.11914 12.0917V6.13304C2.11914 3.91917 3.94016 2.10742 6.16642 2.10742Z" fill={color}/>
  </svg>
)

export const Facebook = ({ color = '#000000' }) => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49751 17.25H1.99846V8.99896H0.25V6.15593H1.99846V4.44907C1.99846 2.12986 2.9852 0.75 5.79043 0.75H8.12527V3.59407H6.66599C5.57394 3.59407 5.50178 3.99137 5.50178 4.73285L5.49697 6.15593H8.1413L7.83181 8.99896H5.49697V17.25H5.49751Z" fill={color}/>
  </svg>
)

export const Search = ({ color = '#000000' }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.491 8.84128C10.1902 7.96429 10.5736 6.85386 10.5736 5.73008C10.5736 2.96155 8.31204 0.700012 5.52952 0.700012C2.74756 0.700012 0.5 2.96155 0.5 5.73008C0.5 8.49862 2.76154 10.7602 5.53007 10.7602C6.6405 10.7602 7.75035 10.3763 8.64126 9.67758L12.1637 13.2L13.0001 12.3776L9.491 8.84128ZM5.53015 9.56782C3.43295 9.56782 1.71967 7.85453 1.71967 5.75733C1.71967 3.66013 3.43295 1.94685 5.53015 1.94685C7.62735 1.94685 9.34064 3.66013 9.34064 5.75733C9.34064 7.85453 7.62735 9.56782 5.53015 9.56782Z" fill={color}/>
  </svg>
)

export const Icon = ({ color = '#FFFFFF' }) => (
  <svg width="101" height="23" viewBox="0 0 101 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.45261 11.3131C4.45261 12.8153 4.69117 14.1628 5.16906 15.3563C5.64695 16.5507 6.30414 17.5603 7.1391 18.3868C7.9756 19.2133 8.96216 19.8451 10.0988 20.283C11.2362 20.7209 12.4698 20.9394 13.8003 20.9394C14.5968 20.9394 15.364 20.7932 16.1028 20.5015C16.8423 20.2099 17.528 19.829 18.159 19.3595C18.7901 18.8893 19.3557 18.3599 19.8559 17.7712C20.3569 17.1825 20.7655 16.5853 21.0841 15.9804L22.6878 16.9532C22.2215 17.6773 21.6705 18.3714 21.0333 19.0356C20.3961 19.6997 19.6604 20.2891 18.8239 20.8024C17.9874 21.3157 17.044 21.7236 15.992 22.026C14.94 22.3284 13.7657 22.4801 12.469 22.4801C10.5921 22.4801 8.88905 22.183 7.35996 21.5889C5.8301 20.9948 4.51956 20.1899 3.42757 19.1741C2.33558 18.159 1.49138 16.9724 0.894215 15.6164C0.298585 14.2605 0 12.8261 0 11.3131C0 10.3081 0.13698 9.3277 0.4094 8.37115C0.682589 7.41537 1.07737 6.51808 1.59527 5.68081C2.11241 4.84354 2.74344 4.0763 3.48913 3.37909C4.23406 2.68265 5.07594 2.0824 6.01402 1.57988C6.9521 1.07737 7.97868 0.688746 9.09298 0.413248C10.2073 0.137749 11.3955 0 12.6591 0C13.9227 0 14.9762 0.15391 15.9943 0.461729C17.0116 0.769548 17.9389 1.17741 18.7747 1.68531C19.6112 2.19321 20.3553 2.77961 21.0094 3.44373C21.6628 4.10785 22.2238 4.80275 22.6894 5.52613L21.0348 6.56348C20.0914 4.8243 19.0332 3.55147 17.862 2.74652C16.69 1.94157 15.3425 1.5391 13.8188 1.5391C12.5113 1.5391 11.2877 1.76611 10.1511 2.22015C9.01295 2.67418 8.021 3.32214 7.1745 4.1648C6.32723 5.00745 5.66157 6.03172 5.17829 7.23606C4.69425 8.44118 4.45338 9.79943 4.45338 11.3124L4.45261 11.3131Z" fill={color}/>
    <path d="M40.4373 22.4795C38.6858 22.4795 37.0513 22.1824 35.5322 21.5883C34.0139 20.9942 32.6949 20.1808 31.5744 19.1496C30.454 18.1177 29.5728 16.9133 28.9303 15.5351C28.2877 14.1576 27.9668 12.6854 27.9668 11.1186C27.9668 10.092 28.1122 9.10393 28.4024 8.15277C28.6917 7.20238 29.1042 6.31586 29.639 5.49475C30.1739 4.67364 30.8195 3.92487 31.5752 3.24998C32.3317 2.57509 33.1705 1.99715 34.0916 1.51619C35.0128 1.03522 36.0109 0.662758 37.0852 0.398033C38.1602 0.133308 39.2776 0.000946045 40.4373 0.000946045C41.597 0.000946045 42.729 0.133308 43.7979 0.398033C44.8668 0.663527 45.8642 1.03599 46.7915 1.51619C47.718 1.99715 48.5568 2.57509 49.3079 3.24998C50.0582 3.92564 50.7039 4.67364 51.2441 5.49475C51.7843 6.31586 52.1991 7.20161 52.4892 8.15277C52.7793 9.10393 52.9248 10.092 52.9248 11.1186C52.9248 12.1452 52.7793 13.1718 52.4892 14.1329C52.1999 15.0949 51.7843 15.9968 51.2441 16.8394C50.7039 17.6821 50.0582 18.4493 49.3079 19.1412C48.5568 19.833 47.7188 20.4271 46.7915 20.9234C45.8642 21.4206 44.8668 21.8046 43.7979 22.0739C42.7283 22.344 41.6078 22.4787 40.4373 22.4787V22.4795ZM40.4373 21.4098C41.7332 21.4098 42.8791 21.1528 43.8749 20.6395C44.8699 20.1262 45.7087 19.4105 46.3913 18.4917C47.0731 17.5736 47.588 16.4878 47.935 15.2342C48.2821 13.9806 48.4553 12.6085 48.4553 11.1178C48.4553 9.62723 48.2821 8.28206 47.935 7.05001C47.588 5.81796 47.0731 4.75676 46.3913 3.86562C45.7087 2.97448 44.8699 2.28266 43.8749 1.79092C42.8791 1.29917 41.7332 1.05369 40.4373 1.05369C39.1414 1.05369 37.9609 1.29917 36.9659 1.79092C35.9701 2.28266 35.1344 2.97371 34.4579 3.86562C33.7807 4.75676 33.2721 5.81873 32.9304 7.05001C32.5895 8.28129 32.4186 9.638 32.4186 11.1178C32.4186 12.5977 32.5895 13.9813 32.9304 15.2342C33.2721 16.4878 33.7807 17.5736 34.4579 18.4917C35.1344 19.4105 35.9701 20.1262 36.9659 20.6395C37.9609 21.1528 39.1176 21.4098 40.4373 21.4098Z" fill={color}/>
    <path d="M59.7891 0.487335H67.6369C68.9898 0.487335 70.1787 0.625084 71.2022 0.900583C72.2257 1.17608 73.0792 1.56239 73.7618 2.05952C74.4443 2.55665 74.9584 3.14843 75.3055 3.8341C75.6525 4.52054 75.8257 5.26854 75.8257 6.07887C75.8257 6.74838 75.6918 7.37556 75.4248 7.95888C75.1577 8.5422 74.7822 9.06857 74.2989 9.53876C73.8149 10.009 73.2323 10.4137 72.5497 10.7546C71.8679 11.0948 71.1168 11.3626 70.298 11.5573L78.5376 21.9785H74.1188L66.254 11.8813H63.5929V21.9785H59.7891V0.487335ZM63.5929 10.7947H65.7939C66.6473 10.7947 67.4345 10.6869 68.1564 10.4707C68.879 10.2544 69.5046 9.9497 70.0333 9.55492C70.562 9.16014 70.9745 8.68226 71.27 8.12049C71.5655 7.55871 71.714 6.92692 71.714 6.22432C71.714 5.57636 71.597 4.98227 71.3638 4.44204C71.1314 3.90182 70.8013 3.44009 70.375 3.05609C69.9479 2.67285 69.4423 2.37273 68.8559 2.15649C68.2703 1.94101 67.6308 1.83251 66.9366 1.83251H63.5929V10.7947Z" fill={color}/>
    <path d="M100.848 0.487335V1.88099H88.923V9.56339H96.9071V10.957H88.923V20.5841H100.848V21.9777H85.1191V0.487335H100.848Z" fill={color}/>
  </svg>
)


