import React from 'react'
import { Icon } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const AppraisalForm = ({ id, location, appraisal_type = null }) => {

  const [state, setState] = React.useState({})
  const [result, setResult] = React.useState('')
  const formRef = React.useRef()

  React.useEffect(() => {
    let type = appraisal_type

    if (location?.pathname?.indexOf('/property/') > -1) {
      type = 'Sales Appraisal'
    } else if (location?.pathname?.indexOf('/rental/') > -1) {
      type = 'Rental Appraisal'
    }

    setState(state => { 
      return { ...state, appraisal_type: type }
    })
  }, [location?.pathname, appraisal_type])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!formRef.current?.checkValidity()) return false

    fetch("https://hooks.zapier.com/hooks/catch/2181815/b7zrmmb/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formRef.current.getAttribute("name"),
        ...state
      })
    })
      .then(() => {
        setResult('success')
      })
      .catch(error => setResult('fail'))
  }

  let props = {
    ref: formRef,
    name: 'appraisal',
    className: 'form',
    onSubmit: handleSubmit,
  }

  if (result === 'success') {
    return (
      <div className='contact__success'>
        <Icon />
        <h4>Thank you</h4>
        <p>We’ll be in touch with you shortly.</p>
      </div>
    )
  }

  return (
    <form {...props}>
      <h4>{ state.appraisal_type ? `Request a ${state.appraisal_type}` : 'Request an Appraisal' }</h4>
      {!location?.pathname?.indexOf('/property/') > -1 && !location?.pathname?.indexOf('/rental/') > -1 && appraisal_type === null && (
        <div className='form__row'>
          <label htmlFor={`${id}_sales_appraisal`}>Type of appraisal required</label>
          <div className='form__radios'>
            <div className='form__radio'>
              <input id={`${id}_sales_appraisal`} type='radio' name="appraisal_type" onChange={handleChange} value="Sales Appraisal" />
              <label htmlFor={`${id}_sales_appraisal`}>Sales Appraisal</label>
            </div>
            <div className='form__radio'>
              <input id={`${id}_rental_appraisal`} type='radio' name="appraisal_type" onChange={handleChange} value="Rental Appraisal" />
              <label htmlFor={`${id}_rental_appraisal`}>Rental Appraisal</label>
            </div>
          </div>
        </div>
      )}
      <div className='form__row form__row--double'>
        <div className='form__column'>
          <label htmlFor={`${id}_first-name-field`}>First Name</label>
          <input className='form__input' type='text' name='firstname' id={`${id}_first-name-field`} placeholder='-' onChange={handleChange} required />
        </div>
        <div className='form__column'>
          <label htmlFor={`${id}_last-name-field`}>Last Name</label>
          <input className='form__input' type='text' name='lastname' id={`${id}_last-name-field`} placeholder='-' onChange={handleChange} required />
        </div>
      </div>
      <div className='form__row'>
        <label htmlFor={`${id}_email-field`}>Contact Email</label>
        <input className='form__input' type='email' name='email' id={`${id}_email-field`} placeholder='-' onChange={handleChange} required />
      </div>
      <div className='form__row'>
        <label htmlFor={`${id}_phone-field`}>Contact Number</label>
        <input className='form__input' type='text' name='mobile' id={`${id}_phone-field`} placeholder='-' onChange={handleChange} required />
      </div>
      <div className='form__row'>
        <label htmlFor={`${id}_addres-field`}>Enter address of property to be appraised</label>
        <input type='text' name='address' id={`${id}_address-field`} placeholder='-' onChange={handleChange} required />
      </div>
      <div className='form__row'>
        <label htmlFor={`${id}_message`}>Additional Comments (optional)</label>
        <textarea className='form__input form__textarea' id={`${id}_message`} name='message' placeholder='-' onChange={handleChange} />
      </div>
      <div className='form__row form__row--submit'>
        <button type='submit' className='btn btn--border'>Request Appraisal</button>
      </div>
    </form>
  )
}

export default AppraisalForm